import React from 'react';

import { CountryFlag } from '@/components/country-flag';
import { Text } from '@/components/text';
import { LocationInterface } from '@/types';
import { tv } from '@/utils/styles';

export interface SearchLabelProps {
  origin?: LocationInterface;
  destination?: LocationInterface;
}

const Flag = ({ location }: { location?: LocationInterface }) => {
  if (!location?.countryCode) return null;

  return (
    <>
      <CountryFlag countryCode={location.countryCode} />
      <span className="text-base font-semibold">{location.identifier?.value}</span>
    </>
  );
};

const SearchLabel = ({ origin, destination }: SearchLabelProps) => {
  if (!origin && !destination) return <Text className="m-0">Search settings</Text>;

  const { base, flagWrapper } = styles();

  return (
    <div className={base()}>
      <span>Departs from</span>
      <div className={flagWrapper()}>
        <Flag location={origin} />
      </div>
      <span>→</span>
      <div className={flagWrapper()}>
        <Flag location={destination} />
      </div>
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'grid grid-flow-col items-center gap-2',
    flagWrapper: 'flex items-center gap-1',
  },
});

export { SearchLabel };
