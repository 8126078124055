import { addWeeks } from 'date-fns';
import { isFunction } from 'lodash';
import { useState } from 'react';
import { DateRange as DateRangeType } from 'react-day-picker';

import { Button } from '@/components/button';
import { Heading } from '@/components/heading';
import { Label } from '@/components/inputs';
import { RadioTabs } from '@/components/inputs/radio-tabs';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/popover';
import { TimePicker } from '@/components/time-picker';
import {
  DrawerAlt,
  DrawerAltContent,
  DrawerAltDescription,
  DrawerAltFooter,
  DrawerAltHeader,
  DrawerAltTitle,
  DrawerAltTrigger,
} from '@/components/ui/drawer-alt';
import { Actions } from '@/components/ui/inputs/advanced-date-picker/actions';
import { DateRangeDisplay } from '@/components/ui/inputs/advanced-date-picker/display';
import { DATE_OPTIONS, DateRangeValue, DateTimeType } from '@/components/ui/inputs/advanced-date-picker/types';
import { getDateLabel } from '@/components/ui/inputs/advanced-date-picker/utils';
import { Calendar } from '@/components/ui/inputs/calendar';
import { UserMessage } from '@/components/ui/user-message';
import { VisuallyHidden } from '@/components/visually-hidden';
import { useWindowSize } from '@/hooks/use-window-size';
import { PortalContainer } from '@/lib/context/portal-container';
import { DEFAULT_DATE_RANGE } from '@/state/standalone-schedules';
import { BREAKPOINTS_AS_NUMBERS } from '@/theme/constants/breakpoints';
import { OptionItem } from '@/types';
import { tv } from '@/utils/styles';

export interface AdvancedDatePickerProps {
  value: DateRangeValue;
  title?: string;
  onChange: (v: DateRangeValue) => void;
  shouldHideActions?: boolean;
  onReset?: () => void;
  onSearch?: () => void;
  isOpen?: boolean;
  canSearch?: boolean;
}

const AdvancedDatePicker = ({
  title,
  onChange,
  value,
  onReset,
  onSearch,
  shouldHideActions = false,
  isOpen: isOpenProp = false,
  canSearch = true,
}: AdvancedDatePickerProps) => {
  const { width } = useWindowSize();
  const [isOpen, setIsOpen] = useState<boolean>(isOpenProp);
  const radioIOptions: OptionItem[] = Object.entries(DATE_OPTIONS).map(([key, label]) => ({
    label,
    value: key,
  }));

  const isSmallScreen = width! < BREAKPOINTS_AS_NUMBERS.MD;

  const handleReset = () => {
    if (isFunction(onReset)) {
      onReset();
    }
  };

  const handleSearch = () => {
    setIsOpen(!isOpen);
    if (isFunction(onSearch)) {
      onSearch();
    }
  };

  const handleDateSelect = (date?: Date | DateRangeType) => {
    if (value.type === 'range') {
      return onChange({
        ...value,
        dateRange: date as DateRangeType,
      });
    }

    return onChange({
      ...value,
      date: date as Date,
    });
  };

  const handleTypeChange = (type: DateTimeType) => {
    if (type === 'range' && !value.dateRange) {
      // Set the current date range start based on the single date that was
      // selected, or the default start date
      const startDate = value.date || DEFAULT_DATE_RANGE.date;
      return onChange({
        ...value,
        type,
        dateRange: { from: startDate, to: addWeeks(startDate, 1) },
        date: undefined,
      });
    }

    if (type !== 'range' && !value.date) {
      // Set the date based on the from value of the date range, or the default date
      const startDate = value.dateRange?.from || DEFAULT_DATE_RANGE.date;
      return onChange({
        ...value,
        type,
        dateRange: undefined,
        date: startDate,
      });
    }

    return onChange({ ...value, type });
  };

  const handleActionClick = (date: DateRangeType) => {
    if (value.type === 'range') return onChange({ ...value, dateRange: date });
    return onChange({ ...value, date: date.from });
  };

  const handleChangeDepartHour = (hour?: number) => {
    onChange({
      ...value,
      departHour: hour,
    });
  };

  const handleChangeArriveHour = (hour?: number) => {
    onChange({
      ...value,
      arriveHour: hour,
    });
  };

  const fromDate = value.dateRange?.from || value.date;
  const toDate = value.dateRange?.to || value.date;

  const { footer, timeWrapper, content, timeBlock, timeInputWrapper, heading, dateDisplay } = styles();

  const renderContents = () => {
    return (
      <div className={content()}>
        {Boolean(title) && (
          <Heading as="h2" variant="h4" className={heading()}>
            {title}
          </Heading>
        )}
        <RadioTabs onChange={handleTypeChange} selected={value.type} options={radioIOptions} />
        {value.type === 'range' && (
          <UserMessage
            noIcon
            body="Note this will only match services that depart after AND arrive before the selected dates."
            className="!m-0 max-w-none max-md:hidden"
          />
        )}
        <div className={timeWrapper()}>
          {['departOn', 'range'].includes(value.type) && (
            <div className={timeBlock()}>
              <Label htmlFor="departure">Earliest departure</Label>
              <div className={timeInputWrapper()}>
                <DateRangeDisplay
                  label="Depart on"
                  dateLabel={fromDate ? getDateLabel({ date: fromDate }, false) : ''}
                  shouldHideIcon
                  className={dateDisplay()}
                />
                <TimePicker label={null} onChange={handleChangeDepartHour} value={value.departHour} />
              </div>
            </div>
          )}
          {['arriveBy', 'range'].includes(value.type) && (
            <div className={timeBlock()}>
              <Label htmlFor="departure">Latest arrival</Label>
              <div className={timeInputWrapper()}>
                <DateRangeDisplay
                  label="Depart on"
                  dateLabel={toDate ? getDateLabel({ date: toDate }, false) : ''}
                  shouldHideIcon
                  className={dateDisplay()}
                />
                <TimePicker label={null} onChange={handleChangeArriveHour} value={value.arriveHour} />
              </div>
            </div>
          )}
        </div>
        {value.type === 'range' ? (
          <Calendar
            mode="range"
            defaultMonth={value.date || DEFAULT_DATE_RANGE.date}
            selected={value.dateRange}
            onSelect={handleDateSelect}
            numberOfMonths={!isSmallScreen ? 2 : 1}
          />
        ) : (
          <Calendar
            mode="single"
            defaultMonth={value.date || DEFAULT_DATE_RANGE.date}
            selected={value.date}
            onSelect={handleDateSelect}
            numberOfMonths={!isSmallScreen ? 2 : 1}
          />
        )}
        {!shouldHideActions ? <Actions onSelect={handleActionClick} /> : null}
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className={footer()}>
        {isFunction(onReset) && (
          <Button variant="link" onClick={handleReset}>
            Clear all
          </Button>
        )}
        <Button onClick={handleSearch} disabled={!canSearch} data-cy="advanced-date-picker-submit" className="ml-auto">
          Search
        </Button>
      </div>
    );
  };

  if (isSmallScreen) {
    return (
      <DrawerAlt open={isOpen} onOpenChange={setIsOpen}>
        <DrawerAltTrigger>
          <DateRangeDisplay
            label={DATE_OPTIONS[value.type]}
            dateLabel={getDateLabel({ date: value.date, dateRange: value.dateRange })}
          />
        </DrawerAltTrigger>
        <DrawerAltContent className="fixed bottom-0 left-0 right-0 max-h-[96%] bg-white">
          <VisuallyHidden>
            <DrawerAltHeader>
              <DrawerAltTitle>Date &amp; time</DrawerAltTitle>
              <DrawerAltDescription>Choose from the options</DrawerAltDescription>
            </DrawerAltHeader>
          </VisuallyHidden>
          <div className="overflow-auto bg-white p-4 pt-0">
            {/* PortalContainer needed as we need the DrawerAlt to be in (default) modal mode. So auto-focusing the TimePicker causes it to close if it's outside the Drawer. We also need the TimePicker Popover to be in modal mode to avoid any focus trap collisions */}
            <PortalContainer>{renderContents()}</PortalContainer>
          </div>
          <DrawerAltFooter className="!-m-4">{renderFooter()}</DrawerAltFooter>
        </DrawerAltContent>
      </DrawerAlt>
    );
  }

  return (
    <div>
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger className="lg:w-full" data-cy="advanced-date-picker-trigger">
          <DateRangeDisplay
            label={DATE_OPTIONS[value.type]}
            dateLabel={getDateLabel({ date: value.date, dateRange: value.dateRange })}
          />
        </PopoverTrigger>
        <PopoverContent className="bg-white" footer={renderFooter()} data-cy="advanced-date-picker-popover-content">
          {renderContents()}
        </PopoverContent>
      </Popover>
    </div>
  );
};

const styles = tv({
  slots: {
    heading: 'm-0',
    footer: 'flex justify-between rounded-b bg-grey-100 p-2 max-md:pl-4 md:p-4',
    timeWrapper: 'flex gap-2',
    content: 'flex flex-col gap-2 rounded bg-white md:gap-4',
    timeBlock: 'flex flex-col [&_div:first-of-type_button]:!rounded-l-none',
    timeInputWrapper: 'flex items-end',
    timeValue:
      '-mr-px flex h-[var(--input-height)] items-center rounded-l-md border border-grey-300 px-1 text-baseSm sm:px-4',
    dateDisplay: '-mr-px rounded-r-none max-sm:px-2.5',
  },
});

export { AdvancedDatePicker };
