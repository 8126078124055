import { format, isSameMonth } from 'date-fns';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import { CaptionProps, useDayPicker, useNavigation } from 'react-day-picker';

import { Heading } from '@/components/heading';
import { cn } from '@/utils/styles';

const buttonStyles = cn(
  'ring-offset-3 grid h-lg w-[40px] cursor-pointer place-items-center rounded-md border border-solid border-white bg-white text-xxs leading-[1] hover:border-grey-300 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-lightBlue-400',
);

const Navbar = (props: CaptionProps) => {
  const { displayMonth } = props;
  const { numberOfMonths } = useDayPicker();
  const { goToMonth, nextMonth, previousMonth, displayMonths } = useNavigation();

  const displayIndex = displayMonths.findIndex((month) => isSameMonth(displayMonth, month));

  const isFirst = displayIndex === 0;
  const isLast = displayIndex === displayMonths.length - 1;

  const hideNext = numberOfMonths > 1 && (isFirst || !isLast);
  const hidePrevious = numberOfMonths > 1 && (isLast || !isFirst);

  return (
    <div className="flex items-center justify-between">
      {!hidePrevious ? (
        <button
          aria-label="previous month"
          type="button"
          className={buttonStyles}
          disabled={!previousMonth}
          onClick={() => previousMonth && goToMonth(previousMonth)}
        >
          <ArrowLeft size={24} />
        </button>
      ) : (
        <div className="w-6" />
      )}
      <Heading variant="h5" as="h3" className="m-0 text-base">
        {format(displayMonth, 'MMMM yyy')}
      </Heading>
      {!hideNext ? (
        <button
          aria-label="next month"
          type="button"
          className={buttonStyles}
          disabled={!nextMonth}
          onClick={() => nextMonth && goToMonth(nextMonth)}
        >
          <ArrowRight size={24} />
        </button>
      ) : (
        <div className="w-6" />
      )}
    </div>
  );
};

export { Navbar };
