import React, { useState } from 'react';
import { Matcher } from 'react-day-picker';
import { useSetRecoilState } from 'recoil';
import { tv } from 'tailwind-variants';

import { ConditionalWrapper } from '@/components/conditional-wrapper';
import { Icon } from '@/components/icon';
import { Label } from '@/components/inputs';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/popover';
import { Calendar } from '@/components/ui/inputs/calendar';
import { lockedDialogIsOpenState } from '@/state/user';
import { formatDateForUser } from '@/utils/format';
import { trackUser } from '@/utils/tracking';

interface DatePickerProps {
  onSelect: (value: Date) => void;
  selected?: Date;
  label?: string;
  placeholder?: string;
  isLocked?: boolean;
  disabled?: boolean;
  isPopoverOpen?: boolean;
  className?: string;
  shouldCloseOnSelect?: boolean;
  disabledDates?: Matcher | Matcher[];
}

export const DatePicker = ({
  selected,
  onSelect,
  isLocked,
  disabled,
  label,
  placeholder = 'Select a date',
  isPopoverOpen = false,
  className,
  shouldCloseOnSelect = true,
  disabledDates,
}: DatePickerProps) => {
  const [month, setMonth] = useState<Date | undefined>(selected);
  const [isOpen, setIsOpen] = useState<boolean>(isPopoverOpen);
  const setLockedDialogIsOpen = useSetRecoilState(lockedDialogIsOpenState);

  const handleSelect = (value: Date | undefined) => {
    if (!value) return;

    onSelect(value);
    if (shouldCloseOnSelect) {
      setIsOpen(false);
    }
  };

  const handleDialogOpen = () => {
    if (isLocked) {
      setLockedDialogIsOpen(true);

      // Analytics Event
      trackUser.event('Opened Datepicker While Locked', {
        locked: true,
      });
    }
  };

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
    if (open) {
      setMonth(selected);
    }
  };

  const formatButtonLabel = () => {
    if (selected) return formatDateForUser(selected);
    return '';
  };

  return (
    <div className={className}>
      <Label htmlFor="date-picker">{label}</Label>
      <Popover onOpenChange={handleOpenChange} open={isOpen}>
        <ConditionalWrapper
          condition={!isLocked}
          // eslint-disable-next-line react/no-unstable-nested-components
          wrapper={(conditionChildren) => {
            return <PopoverTrigger asChild>{conditionChildren}</PopoverTrigger>;
          }}
        >
          <button
            data-testid="datepicker-button"
            type="button"
            disabled={disabled}
            onClick={handleDialogOpen}
            className={triggerButtonStyles({ isOpen, isDisabled: disabled })}
          >
            <span>{formatButtonLabel() || placeholder || 'Select a date'}</span>
            <Icon name={!isLocked ? 'calendar' : 'lock'} className={iconStyles({ isLocked: !!isLocked })} size="base" />
          </button>
        </ConditionalWrapper>
        <PopoverContent className="bg-white p-md">
          <Calendar
            id="date-picker"
            mode="single"
            selected={selected}
            onSelect={handleSelect}
            month={month}
            onMonthChange={setMonth}
            disabled={disabledDates}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};

export const triggerButtonStyles = tv({
  base: 'inline-flex h-[var(--input-height)] items-center justify-between rounded-md border border-solid border-grey-300 bg-white px-3 text-baseSm font-semibold text-text-primary shadow-softer transition-all hover:border-grey-400 sm:px-4',
  variants: {
    isOpen: {
      true: 'border-lightBlue-500',
    },
    isDisabled: {
      true: 'cursor-not-allowed opacity-50 hover:border-grey-300',
    },
  },
});

export const iconStyles = tv({
  base: 'ml-sm mr-0',
  variants: {
    isLocked: {
      true: '[&_svg]:fill-states-locked',
      false: '[&_svg]:fill-text-primary',
    },
  },
});
