import { Calendar } from 'lucide-react';
import React from 'react';

import { tv } from '@/utils/styles';

export interface DateRangeDisplayProps {
  label: string;
  dateLabel: string;
  shouldHideIcon?: boolean;
  className?: string;
}

const DateRangeDisplay = ({ label, dateLabel, shouldHideIcon, className }: DateRangeDisplayProps) => {
  const { wrapper, inner, labelStyles, dateText } = styles();

  return (
    <div className={wrapper({ className })}>
      <span className={inner()}>
        <span className={labelStyles()}>{label}</span>
        <span className={dateText()}>{dateLabel}</span>
      </span>
      {Boolean(!shouldHideIcon) && <Calendar className="ml-auto" size={16} />}
    </div>
  );
};

const styles = tv({
  slots: {
    wrapper:
      'flex h-[--input-height] max-w-[450px] items-center rounded-md border border-grey-300 bg-white px-4 text-baseSm shadow-softer md:max-w-max',
    inner: 'mr-2 flex translate-y-px flex-col items-start',
    labelStyles: 'mb-0 mr-0 text-sm leading-none',
    dateText: 'max-w-44 truncate font-semibold leading-tight',
  },
});

export { DateRangeDisplay };
