import { DateRange as DateRangeType } from 'react-day-picker';

export const DATE_OPTIONS = { departOn: 'Depart on', arriveBy: 'Arrive by', range: 'Date range' } as const;
export type DateTimeType = keyof typeof DATE_OPTIONS;

export interface DateRangeValue {
  type: DateTimeType;
  dateRange?: DateRangeType;
  date?: Date;
  departHour?: number;
  arriveHour?: number;
}
