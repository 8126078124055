import { tv } from '@/utils/styles';

interface Props {
  readonly children: React.ReactNode;
}

const WebBrowserWrapper = ({ children }: Props) => {
  const { base, dot, dots, bar, navbar } = styles();
  return (
    <div className={base()}>
      <div className={navbar()}>
        <span className={dots()}>
          <span className={dot()} style={{ background: '#EB6D62' }} />
          <span className={dot()} style={{ background: '#F3BF59' }} />
          <span className={dot()} style={{ background: '#63C459' }} />
        </span>
        <span className={bar()}>fluentcargo.com</span>
      </div>
      {children}
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'overflow-hidden rounded-md bg-white md:rounded-xl md:shadow-xl',
    navbar: 'hidden h-12 grid-cols-[1fr_3fr_1fr] items-center border-b border-b-grey-300 px-4 md:grid',
    dots: 'flex gap-2',
    dot: 'block h-3 w-3 rounded-full',
    bar: 'block w-full rounded-md bg-grey-200 px-4 text-sm italic leading-[28px] text-black/20',
  },
});

export { WebBrowserWrapper };
