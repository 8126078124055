import { ComponentProps } from 'react';
import { DayPicker } from 'react-day-picker';

import { cn } from '@/utils/styles';

import { Navbar } from './navbar';

export type CalendarProps = ComponentProps<typeof DayPicker>;

const defaultDisabled = [
  {
    before: new Date(),
  },
];

const Calendar = ({ className, classNames, disabled = defaultDisabled, ...props }: CalendarProps) => {
  return (
    <DayPicker
      classNames={{
        months: cn('flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0'),
        table: cn('w-full border-collapse space-y-1'),
        head: cn('text-baseSm'),
        caption: cn('relative flex items-center justify-center pt-1'),
        caption_label: cn('text-sm font-medium'),
        head_cell: cn('h-[40px] w-[40px] text-[0.75em] font-bold uppercase text-grey-500'),
        row: cn('border-b border-b-white'),
        cell: cn(
          'relative p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has(.day-range-end)]:rounded-r-full [&:has(.day-range-start)]:rounded-l-full [&:has([aria-selected])]:bg-lightBlue-50',
          { 'rounded-full': props.mode !== 'range' },
        ),
        day: cn(
          'ring-offset-3 h-[40px] w-[40px] rounded-full p-0 text-baseSm transition-colors hover:rounded-full hover:bg-lightBlue-500 hover:text-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-lightBlue-400 disabled:pointer-events-none disabled:opacity-50 aria-selected:opacity-100',
        ),
        day_selected: cn('selected rounded-full bg-lightBlue-500 text-white'),
        day_range_start: cn('day-range-start'),
        day_range_end: cn('day-range-end'),
        day_disabled: cn('opacity-50'),
        day_range_middle: cn(
          'rounded-none !text-text-primary focus-visible:rounded-full aria-selected:bg-lightBlue-50',
        ),
        day_hidden: cn('invisible'),
        day_today: cn('font-bold'),
        multiple_months: cn('gap-4'),
        caption_start: cn('rounded-md border border-grey-300 p-4'),
        caption_end: cn('rounded-md border border-grey-300 p-4'),
        ...classNames,
      }}
      components={{
        Caption: Navbar,
      }}
      disabled={disabled}
      {...props}
    />
  );
};

export { Calendar };
