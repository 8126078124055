import { addDays } from 'date-fns';
import React from 'react';
import { DateRange } from 'react-day-picker';

import { Button } from '@/components/button';
import { getToday } from '@/utils/format/date-time';

interface ActionsProps {
  onSelect: (value: DateRange) => void;
  onClear?: () => void;
}

export const Actions = ({ onSelect, onClear }: ActionsProps) => {
  const handleClickThisWeek = () => {
    onSelect({ from: getToday(), to: addDays(getToday(), 7) });
  };

  const handleClickNextWeek = () => {
    onSelect({ from: addDays(getToday(), 7), to: addDays(getToday(), 14) });
  };

  return (
    <div className="flex w-full justify-between">
      <div className="flex gap-2">
        <Button size="xsmall" variant="greyLight" onClick={handleClickThisWeek}>
          This week
        </Button>
        <Button size="xsmall" variant="greyLight" onClick={handleClickNextWeek}>
          Next week
        </Button>
      </div>
      {onClear ? (
        <Button size="xsmall" variant="secondaryLight" onClick={onClear}>
          Clear
        </Button>
      ) : null}
    </div>
  );
};
