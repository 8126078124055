import * as SliderPrimitive from '@radix-ui/react-slider';
import React from 'react';

import { Tooltip } from '@/components/tooltip';
import { cn } from '@/utils/styles';

interface RangeProps {
  thumbTooltipLabels?: string[];
}

const thumbStyles = cn(
  'block h-5 w-5 cursor-grab rounded-full border-2 border-lightBlue-500 bg-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 active:cursor-grabbing disabled:pointer-events-none disabled:opacity-50',
);

const Range = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root & RangeProps> & RangeProps,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> & RangeProps
>(({ className, defaultValue = [50], thumbTooltipLabels = [], ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn('relative flex w-full touch-none select-none items-center', className)}
    defaultValue={defaultValue}
    {...props}
  >
    <SliderPrimitive.Track className="relative h-2 w-full grow cursor-grab overflow-hidden rounded-full bg-lightBlue-50 active:cursor-grabbing">
      <SliderPrimitive.Range className="absolute h-full bg-lightBlue-500" />
    </SliderPrimitive.Track>
    {defaultValue.map((_, i) =>
      thumbTooltipLabels.length && thumbTooltipLabels[i] ? (
        <Tooltip content={thumbTooltipLabels[i]} key={i} trigger={['hover', 'focus']}>
          <SliderPrimitive.Thumb className={thumbStyles} />
        </Tooltip>
      ) : (
        <SliderPrimitive.Thumb key={i} className={thumbStyles} />
      ),
    )}
  </SliderPrimitive.Root>
));
Range.displayName = SliderPrimitive.Root.displayName;

export { Range };
