import { CalendarClock } from 'lucide-react';
import { useState } from 'react';

import { Button } from '@/components/button';
import { ConditionalWrapper } from '@/components/conditional-wrapper';
import { iconStyles, triggerButtonStyles } from '@/components/date-picker';
import { Icon } from '@/components/icon';
import { Label } from '@/components/inputs';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/popover';
import { Range } from '@/components/range';
import { zeroPad } from '@/utils/strings';
import { cn } from '@/utils/styles';

const DAY_START_HOURS = 0;
const DAY_END_HOURS = 24;

export interface TimePickerProps {
  value?: number;
  initialValue?: number;
  onChange: (value: number | undefined) => void;
  isInverted?: boolean;
  label?: string | null;
  buttonLabel?: string;
  icon?: string;
  rangeLabel?: string;
  min?: number;
  max?: number;
  isPopoverOpen?: boolean;
  disabled?: boolean;
  noValueLabel?: string;
  isLocked?: boolean;
}

export const TimePicker = ({
  value,
  initialValue,
  onChange,
  min = DAY_START_HOURS,
  max = DAY_END_HOURS,
  icon,
  rangeLabel,
  label = 'Times',
  buttonLabel,
  isPopoverOpen,
  isInverted,
  disabled,
  noValueLabel = 'any time',
  isLocked,
}: TimePickerProps) => {
  const [tempValue, setTempValue] = useState(value || initialValue);
  const [isOpen, setIsOpen] = useState<boolean | undefined>(isPopoverOpen);

  const getHours = (time?: number) => {
    const finalValue = time === DAY_END_HOURS ? 0 : time;
    return `${zeroPad(finalValue || 0, 2)}:00`;
  };

  const getValue = (time: number): number => {
    return isInverted ? max - time : time;
  };

  const getTempValue = (time: number = 0): number => {
    return isInverted ? Math.abs(time - max) : time;
  };

  const getTimeLabel = (time?: number) => {
    if (time === undefined) return noValueLabel;
    return getHours(time);
  };

  const handleClear = () => {
    onChange(undefined);
    setTempValue(initialValue);
  };

  return (
    <div>
      <Label htmlFor="">{label}</Label>
      {/* modal={true} setting required for this to work inside the DrawerAlt component to avoid any focus trap collisions */}
      <Popover open={isOpen} onOpenChange={setIsOpen} modal>
        <ConditionalWrapper
          condition={!isLocked}
          // eslint-disable-next-line react/no-unstable-nested-components
          wrapper={(conditionChildren) => {
            return <PopoverTrigger asChild>{conditionChildren}</PopoverTrigger>;
          }}
        >
          <button type="button" className={cn(triggerButtonStyles({ isOpen, isDisabled: disabled }), 'flex gap-2')}>
            {value !== undefined ? getHours(value) : buttonLabel}
            {isLocked ? (
              <Icon
                name={isLocked ? 'lock' : 'calendar-clock'}
                className={iconStyles({ isLocked: !!isLocked })}
                size="base"
              />
            ) : (
              <CalendarClock size={16} />
            )}
          </button>
        </ConditionalWrapper>
        <PopoverContent className="bg-white p-6">
          <div className="flex flex-col gap-6">
            <div className="flex justify-between [&_svg]:fill-grey-500">
              <div className="flex items-center gap-2">
                {icon ? <Icon name={icon} /> : null}
                <div className="leading-none">
                  <span className="align-text-top text-baseSm">{rangeLabel}</span>{' '}
                  <span className="align-text-top text-baseSm font-medium text-text-primary">
                    {getTimeLabel(value)}
                  </span>
                </div>
              </div>
              <Button className="justify-self-end" variant="secondaryLight" size="small" onClick={handleClear}>
                Clear
              </Button>
            </div>
            <Range
              inverted={isInverted}
              value={[getTempValue(tempValue)]}
              onValueCommit={(newValue) => onChange(getValue(newValue[0]))}
              onValueChange={(newValue) => setTempValue(getValue(newValue[0]))}
              className="w-64 sm:w-72"
              min={min}
              max={max}
              thumbTooltipLabels={[getHours(tempValue)]}
            />
            <span className="text-center text-sm text-grey-400">Drag the slider to select a time</span>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};
